<template>
  <div>
    <b-overlay :show="show" no-wrap fixed z-index="9999">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark"></b-spinner>
          <b-spinner type="grow" variant="dark"></b-spinner>
          <b-spinner small type="grow" variant="dark"></b-spinner>
        </div>
      </template>
    </b-overlay>
    <b-card title="تراکنش ها" class="mt-3">
      <b-button v-b-toggle.filterBox variant="primary" class="mb-3 position-absolute" style="top:20px; left: 20px;"
        size="sm"><i class="fa fa-bars"></i></b-button>
      <b-collapse id="filterBox" class="my-2">
        <b-card>
          <form @submit.prevent="searchItems()" id="search-item">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="title">شماره فاکتور</label>
                  <b-form-input id="title" v-model="searchParams.invoice_number"></b-form-input>
                </div>
              </div>
              <div class="col-md-4">
                <b-form-group label="نام خریدار" label-for="customer">
                  <b-form-input id="customer" v-model="searchParams.customer"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group label="وضعیت" label-for="status">
                  <b-form-select id="status" v-model="searchParams.status">
                    <b-form-select-option value="0">جدید</b-form-select-option>
                    <b-form-select-option value="1">در حال انتظار</b-form-select-option>
                    <b-form-select-option value="2">تایید شده</b-form-select-option>
                    <b-form-select-option value="3">در حال ارسال</b-form-select-option>
                    <b-form-select-option value="4">تحویل داده شده</b-form-select-option>
                    <b-form-select-option value="5">کنسل شده</b-form-select-option>
                    <b-form-select-option value="">همه</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="تاریخ شروع" label-for="start_date">
                  <date-picker type="datetime" v-model="searchParams.start_date" format="jYYYY-jMM-jDD HH:mm" />
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="تاریخ پایان" label-for="end_date">
                  <date-picker type="datetime" v-model="searchParams.end_date" format="jYYYY-jMM-jDD HH:mm" />
                </b-form-group>
              </div>

            </div>
            <button class="btn btn-primary btn-sm float-left" type="submit" :disabled="disabled">جست و
              جو</button>
          </form>
        </b-card>
      </b-collapse>
      <b-table responsive striped hover v-if="items.data" :fields="table_fields" :items="items.data.data">
        <template v-slot:cell(statusTransaction)="data">
          <div>
            <p v-if="data.item.StatusTransaction == '0' " class="p-1 bg-danger text-center small rounded">
              {{data.item.MessageStatusTransaction}}</p>
            <p v-else-if="data.item.StatusTransaction == '1'" class="p-1 bg-success text-center small rounded">
              {{data.item.MessageStatusTransaction}}</p>
          </div>
        </template>
        <template v-slot:cell(status)="data">
          <div>
            <p v-if="data.item.status.status == '0' " class="p-1 bg-secondary text-center small rounded">جدید</p>
            <p v-else-if="data.item.status.status == '1'" class="p-1 bg-warning text-center small rounded">در حال انتظار
            </p>
            <p v-else-if="data.item.status.status == '2' " class="p-1 bg-info text-center small rounded">تایید شده</p>
            <p v-else-if="data.item.status.status == '3' " class="p-1 bg-primary text-center small rounded">در حال ارسال
            </p>
            <p v-else-if="data.item.status.status == '4' " class="p-1 bg-success text-center small rounded">تحویل داده
              شده</p>
            <p v-else-if="data.item.status.status == '5' " class="p-1 bg-danger text-center small rounded">کنسل شده</p>
          </div>
        </template>
        <template v-slot:cell(invoice_number)="data">
          <router-link class="link btn-link" :to="'/admin/invoices/' + data.item.id">#{{ data.item.invoice_number }}
          </router-link>
        </template>

        <template v-slot:cell(customer)="data">
          <router-link :to="'/admin/customers?id=' + data.item.customer.id"
            v-if="data.item.customer && data.item.customer.first_name != null && data.item.customer.last_name != null ">
            {{ data.item.customer.first_name + ' ' + data.item.customer.last_name + ' | دریافت کننده : '+ data.item.name }}
          </router-link>
          <router-link :to="'/admin/customers?id=' + data.item.customer.id" v-else>
            {{ 'دریافت کننده : ' + data.item.name  }}</router-link>
        </template>

        <template v-slot:cell(created_at)="data">
          {{ data.item.created_at | persianDate }}
        </template>

        <template v-slot:cell(AmountOrders)="data">
          {{ numberFormat(data.item.AmountOrders) }}
        </template>
        <template v-slot:cell(details)="data">
          <button class="btn-sm btn btn-info" @click="showInvoiceDetails(data.index)">
            <i class="fa fa-info"></i>
          </button>
        </template>
      </b-table>
      <div class="alert alert-danger text-right" v-if="items.data && items.data.data == ''">
        <span class="text-right">موردی یافت نشد ! </span>
      </div>
    </b-card>

    <pagination :limit="2" v-if="items.data && !search" :data="items.data" @pagination-change-page="loadItems">
    </pagination>
    <pagination :limit="2" v-else-if="items.data && search" :data="items.data" @pagination-change-page="searchItems">
    </pagination>


    <b-modal id="inovice-details" title="جزییات صورت حساب" size="lg" hide-footer>
      <div>
        <table class="table table-bordered" v-if="! $root.isEmptyObject(invoice)">
          <tbody>
            <tr>
              <th colspan="2">شناسه تراکنش</th>
              <td colspan="2">{{ invoice.transaction_id ? invoice.transaction_id : 'ندارد' }}</td>
            </tr>
            <tr>
              <th colspan="2">وضعیت تراکنش</th>
              <td colspan="2">{{ invoice.MessageStatusTransaction ? invoice.MessageStatusTransaction : 'ندارد' }}</td>
            </tr>
            <tr>
              <th colspan="2">وضعیت صورتحساب</th>
              <td colspan="2">
                <form @submit.prevent="invoiceStatus" id="invoice-status">
                  <b-row>
                    <b-col cols="9">
                      <b-form-group :disabled="invoice.StatusTransaction == '0' ? true : false">
                        <b-form-select id="status" name="status" v-model="invoice.status.status">
                          <b-form-select-option value="0">جدید</b-form-select-option>
                          <b-form-select-option value="1">در حال انتظار</b-form-select-option>
                          <b-form-select-option value="2">تایید شده</b-form-select-option>
                          <b-form-select-option value="3">در حال ارسال</b-form-select-option>
                          <b-form-select-option value="4">تحویل داده شده</b-form-select-option>
                          <b-form-select-option value="5">کنسل شده</b-form-select-option>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col cols="2">
                      <button class="btn btn-primary"
                        :disabled="invoice.StatusTransaction == '0' ? true : false">تغییر</button>
                    </b-col>
                  </b-row>
                </form>
              </td>
            </tr>

            <tr>
              <th colspan="2">آدرس</th>
              <td colspan="2">{{ invoice.address }}</td>
            </tr>
            <tr> 
              <th>هزینه ارسال (تومان)</th>
              <td> {{ numberFormat(invoice.delivery_amount) }}</td>
              <td>شیوه ارسال</td>
              <td>
                <span v-if="invoice.shipping_method == 'post'">پست معمولی</span>
                <span v-else-if="invoice.shipping_method == 'pishtaz'">پست پیشتاز</span>
                <span v-else-if="invoice.shipping_method == 'tipax'">پست تیپاکس</span>
              </td>
            </tr>
            <tr v-if="invoice.coupon">
              <th colspan="2">کوپن استفاده شده</th>
              <td colspan="2">{{ invoice.coupon.title }} - ({{ invoice.coupon.code }})</td>
            </tr>
            <tr>
              <th colspan="2">توضیحات سفارش</th>
              <td colspan="2">{{ invoice.description ? invoice.description : 'ندارد' }}</td>
            </tr>
            <tr>
              <th colspan="2">شماره پیگیری</th>
              <td colspan="2">{{ invoice.tracking_code ? invoice.tracking_code : 'ندارد' }}</td>
            </tr>
          </tbody>
        </table>
        <div class="text-left mb-2">
          <a :href="'/admin/invoice/print/' + invoice.id" target="_blank" class="btn btn-info "><i
              class="fa fa-print"></i></a>
        </div>
      </div>
      
    </b-modal>
    <b-modal id="order-detail" :title="'اقلام تهیه شده توسط فروشنده : ' + seller.name + ' ' + seller.last_name"
      hide-footer size="xl">
      <form @submit.prevent="orderUpdate" id="order-update">
        <div v-if="orderDetail.orders" class="box-shadow mt-3 rounded pt-1">
          <b-table responsive striped hover class="mb-0" :fields="order_detail_table_fields"
            :items="orderDetail.orders">
            <template v-slot:cell(quantity_send)="data">
              <div class="d-flex justify-content-start align-items-center">
                <span class=" cursor-pointer increment p-0 text-center" 
                  @click="increment(data.index) ">
                  <i class="fas fa-plus"></i>
                </span>
                <span class="border rounded " style="height: 30px; line-height: 5px;" >
                  {{data.item.pivot ? data.item.pivot.available_count : 0}}
                </span>
                <span class=" cursor-pointer increment p-0 text-center" 
                  @click="decrement(data.index)">
                  <i class="fas fa-minus"></i>
                </span>
              </div>
            </template>
            <template v-slot:cell(price)="data">
              <b-form-input v-if="data.item.pivot.price != null" :value="data.item.pivot.price | price"
                @input="value=>{data.item.pivot.price = +value.replace(/\D/g, '')}"></b-form-input>
            </template>
            <template v-slot:cell(quantity)="data">
              {{data.item.quantity}}
            </template>
            <template v-slot:cell(wanted_count)="data">
              <div class="d-flex justify-content-start align-items-center">
                <span class=" cursor-pointer increment p-0 text-center" 
                  @click="incrementWantedCount(data.index) ">
                  <i class="fas fa-plus"></i>
                </span>
                <span class="border rounded " style="height: 30px; line-height: 5px;" >
                  {{data.item.pivot ? data.item.pivot.wanted_count : 0}}
                </span>
                <span class=" cursor-pointer increment p-0 text-center" 
                  @click="decrementWantedCount(data.index)">
                  <i class="fas fa-minus"></i>
                </span>
              </div>
            </template>
            <template v-slot:cell(title)="data">
              {{ data.item.variety ? data.item.variety.fullTitle : ''  }}
            </template>
            <template v-slot:cell(index)="data">
              {{ data.index + 1  }}
            </template>
          </b-table>
        </div>
        <div class="d-flex justify-content-between align-items-center mt-5 ">
          <div class="d-flex justify-content-center">
            <span class="fontWeightBold mt-2 ml-1">
              وضعیت کلی فاکتور :
            </span>
            <b-form-select v-model="orderDetail.status" style="width: 200px;" name="status">
              <b-form-select-option value="init">ثبت شده</b-form-select-option>
              <b-form-select-option value="sending">در حال ارسال</b-form-select-option>
              <b-form-select-option value="unavailable">موجود نیست</b-form-select-option>
              <b-form-select-option value="sent">ارسال شد</b-form-select-option>
            </b-form-select>
          </div>
          <div>
            <button class="btn btn-success" type="submit" :disabled="disabled"> ثبت وضعیت</button>
          </div>

        </div>

      </form>
    </b-modal>
  </div>
</template>
<script>
  import mixins from '../mixins/mixins'

  export default {
    mixins: [mixins],
    data() {
      return {
        url: '/api/admin/orders',
        title: 'تراکنش ها',
        invoice: {},
        table_fields: [{
            key: 'id',
            label: 'شناسه'
          },
          {
            key: 'invoice_number',
            label: 'شماره فاکتور'
          },
          {
            key: 'customer',
            label: 'نام خریدار'
          },
          {
            key: 'AmountOrders',
            label: 'مبلغ فاکتور (تومان)'
          },
          {
            key: 'statusTransaction',
            label: 'وضعیت تراکنش'
          },
          {
            key: 'status',
            label: 'وضعیت صورتحساب'
          },
          {
            key: 'created_at',
            label: 'تاریخ'
          },
          {
            key: 'details',
            label: 'جزییات'
          }
        ],
        seller_fields: [{
            key: 'id',
            label: 'شناسه'
          },
          {
            key: 'name',
            label: 'نام و نام خانوادگی'
          },
          {
            key: 'company_name',
            label: 'نام شرکت'
          },
          {
            key: 'phone_number',
            label: 'شماره تماس'
          },
          {
            key: 'status',
            label: 'وضعیت سفارش'
          },
          {
            key: 'details',
            label: 'اقلام'
          },
        ],
        order_detail_table_fields: [{
            key: 'title',
            label: 'عنوان'
          },
          {
            key: 'quantity',
            label: 'تعداد سفارش کاربر'
          },
          {
            key: 'wanted_count',
            label: 'تعداد درخواست'
          },
          {
            key: 'quantity_send',
            label: 'تعداد ارسال'
          },
          {
            key: 'error',
            label: 'توضیحات'
          },
          {
            key: 'price',
            label: 'قیمت'
          },
        ],
        orderDetail: {},
        seller: {
          name: '',
          last_name: ''
        }
      }
    },
    mounted() {
      this.loadItems()
    },
    methods: {
      showInvoiceDetails(index) {
        this.disabled = true
        this.$axios.get(this.$root.baseUrl + this.url + `/${this.items.data.data[index].id}`)
          .then(response => {
            this.invoice = response.data.data.invoice
            this.disabled = false
            this.$root.$emit('bv::show::modal', 'inovice-details')
          })

      },
      invoiceStatus() {
        this.disabled = true
        let form = document.getElementById('invoice-status')
        let formData = new FormData(form)
        this.$axios.post(this.$root.baseUrl + `/api/admin/change/status/${this.invoice.id}`, formData)
          .then(response => {
            window.swal({
              title: 'وضعیت با موفقیت تغییر کرد',
            })
            this.disabled = false
          })
          .catch(error => {
            this.$root.error_notification(error)
            this.disabled = false
          })
      },
      showOrderDetails(index) {
        let item = window.clone(this.invoice.provides[index])
        item.index = index
        this.orderDetail = item
        this.seller.name = this.orderDetail.seller.name
        this.seller.last_name = this.orderDetail.seller.last_name
        this.$root.$emit('bv::show::modal', 'order-detail')
      },
      increment(index) {
        if (this.orderDetail.orders[index].pivot.available_count < this.orderDetail.orders[index].pivot
          .wanted_count) {
          this.orderDetail.orders[index].pivot.available_count = this.orderDetail.orders[index].pivot.available_count +
            1
        }
      },
      decrement(index) {
        if (this.orderDetail.orders[index].pivot.available_count > 0) {
          this.orderDetail.orders[index].pivot.available_count = this.orderDetail.orders[index].pivot.available_count -
            1
        }
      },
      incrementWantedCount(index) {
        this.orderDetail.orders[index].pivot.wanted_count = this.orderDetail.orders[index].pivot.wanted_count + 1
      },
      decrementWantedCount(index) {
        if (this.orderDetail.orders[index].pivot.wanted_count > 0) {
          this.orderDetail.orders[index].pivot.wanted_count = this.orderDetail.orders[index].pivot.wanted_count - 1
        }
      },
      orderUpdate() {
        this.disabled = true
        let form = document.getElementById('order-update')
        let formData = new FormData(form)
        this.orderDetail.orders.map((el,index) => {
          formData.append('orders[' + index + `][id]`, el.id)
          formData.append('orders[' + index + `][available_count]`, el.pivot.available_count)
          formData.append('orders[' + index + `][wanted_count]`, el.pivot.wanted_count)
          formData.append('orders[' + index + `][price]`, el.pivot.price)
        })
        this.$axios.post(this.$root.baseUrl +
            `/api/admin/sellers/${this.orderDetail.seller_id}/provides/${this.orderDetail.id}`, formData)
          .then((response) => {
            this.$root.success_notification(response.data.message)
            this.invoice.provides[this.orderDetail.index].status = this.orderDetail.status
            this.invoice.provides[this.orderDetail.index].orders = response.data.data.orders
          })
          .catch((error) => {
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.disabled = false
          })
      },
      
    },
    created() {
      this.searchParams = {
        invoice_number: '',
        status: '',
        start_date: '',
        end_date: '',
        customer: ''
      }
    }
  }
</script>
<style scoped>
  .box-shadow {
    box-shadow: 5px 9px 25px 6px #0a0a0a21;
    overflow: hidden;
  }

  .cursor-pointer {
    cursor: pointer !important;
  }

  .increment {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .increment .fas {
    font-size: 11px;
  }
</style>
<style>
  .table-responsive span {
    font-size: 13px !important;
  }
</style>